body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* hide the recaptcha icon */
.grecaptcha-badge { 
  visibility: hidden; 
}

.gutter {
  background-color: lightgrey;
}

.gutter-horizontal {
  cursor: ew-resize;
}

/* pop up */
.custom-popup{
  max-width: 620px !important;
}

.custom-popup .mapboxgl-popup {
  display: flex;
  left: 0;
  pointer-events: none;
  position: absolute;
}

.custom-popup .mapboxgl-popup-content {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0,0,0,.1);
  pointer-events: auto;
  position: relative;
  top: -30px;
  padding: 0;
  width: 620px;
}

.custom-popup .mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-popup-close-button {
  font-size: 24px;
}
